<template>
  <div class="table-container">
    <Notification
      v-if="success"
      type="success"
    >
      {{ success }}
    </Notification>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <table
      v-if="data.length"
      class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
    >
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Status</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r in data"
          :key="r.id"
        >
          <td>
            <span v-if="r.paypal_payout">
              {{ r.paypal_payout.submitted_at }}
            </span>
            <span v-else-if="r.status == 'pending'">
              Next Quarter
            </span>
          </td>
          <td>
            <span v-if="r.paypal_payout">
              PayPal
            </span>
          </td>
          <td>{{ r.status }}</td>
          <td>{{ USD(r.amount).format() }} {{ r.currency }}</td>
          <td>
            <ConfirmButton
              v-if="r.paypal_payout"
              title="Cancel Payout"
              buttonLabel="Cancel"
              :submitting="submittingCancelPayout"
              :disabled="!canCancelPayout(r)"
              @confirm="cancelPayout(r)"
            >
              <p>Are you sure your want to cancel this {{ USD(r.amount) }} {{ r.currency }} payout? You will need to wait until the next payout period to receive your payout.</p>
            </ConfirmButton>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-else
      class="table is-bordered is-hoverable is-fullwidth"
    >
      <tr>
        <td>
          No payouts yet. Earn at least $25 to receive a payout.
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import api from "@/service/api.js";

export default {
  components: {
    Notification,
    ConfirmButton
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      success: null,
      error: null,
      submittingCancelPayout: false,
      currentPayout: null
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
  },
  methods: {
    canCancelPayout(payout) {
      return (payout.status == "unclaimed")
    },
    cancelPayout(payout) {
      if (!this.canCancelPayout(payout)) {
        this.error = `You cannot cancel ${this.USD(payout.amount).format()} ${payout.currency} payout at this time!`
        return
      }

      this.submittingCancelPayout = true

      api.cancelPayout(this.currentUser.slug, payout.id)
        .then(resp => {
          console.log(resp)
          this.success = `Successfully cancelled ${this.USD(payout.amount).format()} ${payout.currency} payout`
          this.submittingCancelPayout = false
          this.$emit('change')
        })
        .catch(error => {
          console.log(error)
          this.error = `An error occurred while attempting to cancel a ${this.USD(payout.amount).format()} ${payout.currency} payout!`
          this.submittingCancelPayout = false
        })
    },
  }
};
</script>

<style scoped>
td > div {
  display: inline-block;
}
</style>
