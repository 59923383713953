<template>
  <div>
    <section class="container section">
      <div class="columns">
        <div class="column is-one-quarter">
          <label class="label">{{ label }}</label>
          <aside class="menu">
            <p class="menu-label">
              Settings
            </p>
            <ul class="menu-list">
              <li
                v-for="l in links"
                :key="l.path"
              >
                <a @click="go(l.path)" :class="isActive(l.name)">{{l.label}}</a>
              </li>
            </ul>
          </aside>
          <button
            class="button is-outlined is-link is-small"
            @click="back"
          >
            <span class="icon">
              <i class="fas fa-arrow-left"></i>
            </span>
            <span>Back</span>
          </button>
        </div>
        <div class="column">
          <slot />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "user"
    },
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness
    },
    label: function() {
      switch(this.type) {
        case "user":
          return this.currentUser.name
        case "business":
          return this.currentBusiness.name
        default:
          return ""
      }
    },
    links: function() {
      return display_links(this.type)
    },
  },
  methods: {
    isActive (name) {
      const found = name === this.$route.name
      if (found) {
        return 'active'
      } else {
        return null
      }
    },
    go(path) {
      this.$router.push(path);
    },
    back() {
      this.$router.go(-1)
    },
    reset() {
      this.$parent.reset()
    },
    addLocation(location) {
      this.$parent.addLocation(location)
    },
    uploadImg(image_url) {
      this.$parent.uploadImg(image_url)
    }
  }
};

function display_links(type) {
  if (type == 'user') {
    return user_links();
  } else {
    return org_links();
  }
}

function user_links() {
  return [
    {
      path: '/profile',
      label: 'General',
      name: 'profile'
    },
    {
      path: '/donations',
      label: 'Donations',
      name: 'donations'
    },
    {
      path: '/transactions',
      label: 'History',
      name: 'transactions'
    },
    {
      path: '/payouts',
      label: 'Payouts',
      name: 'payouts'
    },
  ]
}

function org_links() {
  return [
    {
      path: '/settings-general',
      label: 'General',
      name: 'settings-general'
    },
    {
      path: '/settings-users',
      label: 'Team',
      name: 'settings-users'
    },
    {
      path: '/settings-billing',
      label: 'Billing',
      name: 'settings-billing'
    }
  ]
}

</script>

<style scoped>
.active {
  background-color: #eee;
  border-left: 2px solid blue;
}
aside + button {
  margin-top: 1rem;
}
</style>
