<template>
  <SettingsPage>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Paypal Address" 
        type="email"
        v-model.trim="payoutEmail"
        :validation="$v.payoutEmail"
        @input="detectChange()"
      />
      <BaseSubmit
        label="Save"
        :disabled="$v.$invalid || noChanges"
        :submitting="submitting"
        :clearShow="true"
      />
    </form>

    <div style="margin-top: 30px;">
      <label class="label">Account Balance</label>
      <p>{{ USD(currentUser.balance).format() }}</p>
    </div>

    <div style="margin-top:30px;">
      <label class="label">Payout History</label>
      <p style="margin-bottom:30px;"><Brand /> will send a payout every 90 days if balance is over $25</p>
      <PayoutsTable
        :data="payouts"
        @change="updatePayouts"
      />
    </div>

  </SettingsPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js";
import { email } from 'vuelidate/lib/validators';
import SettingsPage from "@/components/SettingsPage.vue";
import Brand from "@/components/Brand.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import PayoutsTable from "@/components/table/Payouts.vue";
import api from "@/service/api.js";

export default {
  components: {
    SettingsPage,
    Brand,
    Notification,
    BaseInput,
    BaseSubmit,
    PayoutsTable
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      noChanges: true,
      payoutEmail: '',
      payouts: []
    }
  },
  validations: {
     payoutEmail:  { email }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    }
  },
  mounted() {
    this.payoutEmail = this.currentUser.payout_email
    this.updatePayouts()
  },
  methods: {
    reset() {
      this.payoutEmail = this.currentUser.payout_email
    },
    detectChange() {
      this.noChanges = false
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let params = {
        slug: this.currentUser.slug,
        user: {
          payout_email: this.payoutEmail
        }
      }
      this.$store.dispatch("updateCurrentUser", params)
        .then(() => {
          this.noChanges = true
          this.submitting = false;
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error
          }
        })
    },
    updatePayouts() {
      api.getPayouts(this.currentUser.slug)
        .then(resp => {
          this.payouts = []
          // insert upcoming payout (if over $25)
          if (this.currentUser.payout_email && this.currentUser.balance >= 2500) {
            this.payouts.push({
              id: -1,
              status: "pending",
              amount: this.currentUser.balance,
              currency: "USD"
            })
          }
          // append payout history
          this.payouts.concat(resp.data)
        })
        .catch(error => {
          this.error = error.data
        })
    }
  }
}
</script>

